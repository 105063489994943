@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wdth,wght@80,300;100,300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital@1&display=swap");
body {
  font-family: 'Open Sans', sans-serif;
  padding: 0;
  margin: 0;
}

.header {
  padding-top: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

@media (min-width: 576px) {
  .header {
    display: block;
  }
}

#header-logo {
  max-height: 130px;
  max-width: 2080px;
}

@media (min-width: 576px) {
  #header-logo {
    padding-left: 10%;
    max-height: 180px;
    max-width: 2080px;
  }
}

.header-title {
  margin: 0;
  color: #003049;
  font-size: 48px;
}

#hero-image {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 48, 73, 0.2)), to(rgba(0, 48, 73, 0.6))), -webkit-gradient(linear, left bottom, left top, from(rgba(0, 48, 73, 0.1)), to(rgba(0, 48, 73, 0.6))), url("../assets/office_1.png");
  background-image: linear-gradient(to bottom, rgba(0, 48, 73, 0.2), rgba(0, 48, 73, 0.6)), linear-gradient(to top, rgba(0, 48, 73, 0.1), rgba(0, 48, 73, 0.6)), url("../assets/office_1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-box-shadow: 1px 1px 68px 17px #003049;
          box-shadow: 1px 1px 68px 17px #003049;
}

.layout {
  width: 100%;
  margin: auto;
}

@media (min-width: 576px) {
  .layout {
    width: 80%;
  }
}

@media (min-width: 768px) {
  .layout {
    width: 70%;
  }
}

.intro-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 3rem;
  text-align: center;
  height: 50vh;
}

.intro-text {
  color: #FFFFFF;
  font-size: 26px;
}

@media (min-width: 576px) {
  .intro-text {
    font-size: 46px;
  }
}

.intro-text:nth-child(2) {
  margin: 0 0 2rem 0;
}

.intro-contact-text {
  cursor: pointer;
  color: #FFFFFF;
  font-weight: bold;
  background: transparent;
  border: none;
  margin: 30px auto 0;
  display: block;
  font-size: 32px;
  outline: none;
  position: relative;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  padding: 10px;
}

.intro-contact-text:before {
  content: '';
  border-bottom: 2px solid #FFFFFF;
  padding: 0 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.intro-contact-text:hover:before {
  width: 100%;
  padding: 0;
}

.services-text {
  font-size: 42px;
  text-align: center;
  margin-top: 0;
}

@media (min-width: 576px) {
  .services-text {
    margin-bottom: 4rem;
    margin-top: 6rem;
  }
}

.service-icons-wrapper {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr);
  -ms-grid-rows: (1fr)[4];
      grid-template-rows: repeat(4, 1fr);
  margin-bottom: 4rem;
  margin-top: 2rem;
}

@media (min-width: 576px) {
  .service-icons-wrapper {
    -ms-grid-columns: (1fr)[3];
        grid-template-columns: repeat(3, 1fr);
    -ms-grid-rows: (1fr)[3];
        grid-template-rows: repeat(3, 1fr);
  }
}

@media (min-width: 768px) {
  .service-icons-wrapper {
    -ms-grid-columns: (1fr)[4];
        grid-template-columns: repeat(4, 1fr);
    -ms-grid-rows: (1fr)[2];
        grid-template-rows: repeat(2, 1fr);
    max-width: 80%;
    margin-bottom: 2rem;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 5rem;
  }
}

.service-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #003049;
}

.service-image-wrapper {
  border: 1px solid #003049;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 5rem;
  height: 5rem;
  overflow: hidden;
  border-radius: 50%;
}

@media (min-width: 576px) {
  .service-image-wrapper {
    width: 8rem;
    height: 8rem;
  }
}

.service-image {
  width: 100%;
  height: 100%;
  max-width: 3rem;
  max-height: 3rem;
  -webkit-filter: invert(14%) sepia(31%) saturate(3299%) hue-rotate(173deg) brightness(94%) contrast(101%);
          filter: invert(14%) sepia(31%) saturate(3299%) hue-rotate(173deg) brightness(94%) contrast(101%);
}

@media (min-width: 576px) {
  .service-image {
    max-width: 5rem;
    max-height: 5rem;
  }
}

.service-icon-title {
  font-weight: 600;
  font-size: 16px;
}

@media (min-width: 576px) {
  .service-icon-title {
    font-size: 20px;
  }
}

.secondary-intro-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: 0 0.5rem;
}

@media (min-width: 768px) {
  .secondary-intro-wrapper {
    margin-bottom: 6rem;
  }
}

.secondary-intro-text {
  font-size: 42px;
  text-align: center;
  margin-top: 0;
}

@media (min-width: 768px) {
  .secondary-intro-text {
    margin-bottom: 4rem;
    margin-top: 4rem;
  }
}

.secondary-intro-checkmark-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

@media (min-width: 768px) {
  .secondary-intro-checkmark-container {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    font-size: 20px;
  }
}

.checkmark-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 5rem;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1rem;
  margin: 0 1rem;
}

.checkmark-text {
  font-size: 18px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  text-decoration-thickness: 1px;
  margin: 0;
}

@media (min-width: 576px) {
  .checkmark-text {
    font-size: 20px;
  }
}

@media (min-width: 768px) {
  .checkmark-text {
    font-size: 24px;
  }
}

.checkmark-image {
  -webkit-filter: invert(15%) sepia(31%) saturate(2894%) hue-rotate(170deg) brightness(91%) contrast(105%);
          filter: invert(15%) sepia(31%) saturate(2894%) hue-rotate(170deg) brightness(91%) contrast(105%);
  height: 3rem;
}

.contact-section-wrapper {
  margin-top: 4rem;
  color: #FFFFFF;
  font-size: 36px;
  background-image: url("../assets/dark_blue_background.png");
  height: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  padding: 2rem;
  -webkit-box-shadow: -1px -3px 45px 6px #003049;
          box-shadow: -1px -3px 45px 6px #003049;
}

.contact-section-wrapper #contact-form-wrapper {
  -ms-grid-column: 1;
      grid-column-start: 1;
  -ms-grid-row: 1;
      grid-row-start: 1;
}

@media (min-width: 768px) {
  .contact-section-wrapper {
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
  }
}

.contact-section-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 24px;
  width: auto;
  margin-top: 5rem;
}

@media (min-width: 768px) {
  .contact-section-info {
    margin-top: 0;
    font-size: 30px;
  }
}

.contact-section-item {
  margin-top: 1rem;
  text-align: center;
}

#contact-form-title {
  text-align: center;
  margin: 0;
}

#contact-form-subtitle {
  text-align: center;
  margin: 0;
  font-size: 20px;
}

#contact-form-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 0.5rem;
  width: 80%;
  margin: auto;
}

.contact-form-input {
  height: 2rem;
  padding: 5px;
  font-size: 20px;
  color: #003049;
}

.contact-form-input:focus {
  outline: none;
}

.contact-form-message-input {
  height: 15rem;
  padding: 5px;
  font-family: inherit;
  font-size: 20px;
  font-weight: 600;
  resize: none;
}

.send-button {
  height: 3rem;
  width: 10rem;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  border-radius: 10px;
  border: 1px solid #003049;
  cursor: pointer;
  background-color: #FFFFFF;
  color: #003049;
  font-size: 20px;
}

.send-button {
  height: 3rem;
  width: 10rem;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  border-radius: 10px;
  border: 1px solid #003049;
  cursor: pointer;
  background-color: #FFFFFF;
  color: #003049;
  font-size: 20px;
}

.send-button:hover {
  background-color: rgba(255, 255, 255, 0.7);
}

.send-button[disabled] {
  background-color: rgba(211, 211, 211, 0.7);
  cursor: default;
}

#message-info-text {
  text-align: center;
}

.footer-wrapper {
  margin: 0;
  color: #003049;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}

.footer-text {
  margin: 0;
  padding: 1rem 0;
}
