@import "./colors";
// Google Fonts
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wdth,wght@80,300;100,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital@1&display=swap');

// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;

// Small devices
@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
      @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$screen-md-min}) {
      @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
      @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
      @content;
  }
}

body {
  font-family: 'Open Sans', sans-serif;
  padding: 0;
  margin: 0;
}

// .header {
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   margin: 0;
//   padding: 0 0;
//   background-color: $white;
//   // 576px window width and more
//   @include sm {
//     display: flex;
//     align-items: center;
//     justify-content: flex-start;
//     height: 10rem;
//   }
// }

.header {
  padding-top: 2rem;
  display: flex;
  justify-content: center;
  @include sm {
    display: block
  }
}

#header-logo {
  max-height: 130px;
  max-width: 2080px;
  @include sm {
    padding-left: 10%;
    max-height: 180px;
    max-width: 2080px;
  }
}

.header-title {
  margin: 0;
  color: $dark-blue;
  font-size: 48px;
}

#hero-image {
  background-image: 
  linear-gradient(to bottom, rgba($dark-blue, .2), rgba($dark-blue, .6)),
  linear-gradient(to top, rgba($dark-blue, .1),  rgba($dark-blue, .6)),
  url("../assets/office_1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 1px 1px 68px 17px $dark-blue;
}

.layout {
  width: 100%;
  margin: auto;


  @include sm {
    width: 80%;
  }
  @include md {
    width: 70%;
  }
}

.intro-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 3rem;
  text-align: center;
  height: 50vh;
}

.intro-text {
  color: $white;
  // font-family: 'Playfair Display', serif;
  font-size: 26px;
  // 576px window width and more
  @include sm {
    font-size: 46px;
  }
}

.intro-text:nth-child(2) {
  margin: 0 0 2rem 0;
}

.intro-contact-text {
  cursor: pointer;
  color: $white;
  font-weight: bold;
  background: transparent;
  border: none;
  margin: 30px auto 0;
  display: block;
  font-size: 32px;
  outline: none;

  position: relative;
  transition: 0.3s;
  padding: 10px;
}

.intro-contact-text:before {
  content: '';
  border-bottom: 2px solid $white;
  padding: 0 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 0;
  transition: 0.3s;
}

.intro-contact-text:hover:before {
  width: 100%;
  padding: 0;
}


.services-text {
  font-size: 42px;
  text-align: center;
  margin-top: 0;
  @include sm {
    margin-bottom: 4rem;
    margin-top: 6rem;
  }
}

.service-icons-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 1fr);
  margin-bottom: 4rem;
  margin-top: 2rem;
  @include sm {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
  @include md {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    max-width: 80%;
    margin-bottom: 2rem;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 5rem;
  }
}

.service-icon {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: $dark-blue;
}

.service-image-wrapper {
  border: 1px solid $dark-blue;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
  overflow: hidden;
  border-radius: 50%;
  @include sm {
    width: 8rem;
    height: 8rem;
  }
}

.service-image {
  width: 100%;
  height: 100%;
  max-width: 3rem;
  max-height: 3rem;
  filter: invert(14%) sepia(31%) saturate(3299%) hue-rotate(173deg) brightness(94%) contrast(101%);
  @include sm {
    max-width: 5rem;
    max-height: 5rem;
  }
}

.service-icon-title {
  font-weight: 600;
  font-size: 16px;
  @include sm {
    font-size: 20px;
  }
}

.secondary-intro-wrapper {
  display: flex;
  flex-direction: column;
  margin: 0 0.5rem;
  @include md {
    margin-bottom: 6rem;
  }
}

.secondary-intro-text {
  font-size: 42px;
  text-align: center;
  margin-top: 0;
  @include md {
    margin-bottom: 4rem;
    margin-top: 4rem;
  }
}

.secondary-intro-checkmark-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include md {
    flex-direction: row;
    font-size: 20px;
  }
}

.checkmark-wrapper {
  display: flex;
  height: 5rem;
  align-items: center;
  gap: 1rem;
  margin: 0 1rem;
}

.checkmark-text {
  font-size: 18px;
  text-decoration-line: underline;
  text-decoration-thickness: 1px;
  margin: 0;
  @include sm {
    font-size: 20px;
  }
  @include md {
    font-size: 24px;
  }
}

.checkmark-image {
  filter: invert(15%) sepia(31%) saturate(2894%) hue-rotate(170deg) brightness(91%) contrast(105%);
  height: 3rem;
}

.contact-section-wrapper {
  margin-top: 4rem;
  color: $white;
  font-size: 36px;
  background-image: url("../assets/dark_blue_background.png");
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  padding: 2rem;
  box-shadow: -1px -3px 45px 6px $dark-blue;
  #contact-form-wrapper {
    grid-column-start: 1;
    grid-row-start: 1;
  }
  @include md {
    grid-template-columns: 1fr 1fr;
   }

}

.contact-section-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 24px;
  width: auto;
  margin-top: 5rem;
  @include md {
    margin-top: 0;
    font-size: 30px;
   }
}

.contact-section-item {
  margin-top: 1rem;
  text-align: center;
}

#contact-form-title {
  text-align: center;
  margin: 0;
}

#contact-form-subtitle {
  text-align: center;
  margin: 0;
  font-size: 20px;
}

#contact-form-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 80%;
  margin: auto;
}

.contact-form-input {
  height: 2rem;
  padding: 5px;
  font-size: 20px;
  color: $dark-blue;
}

.contact-form-input:focus {
  outline: none;
}

.contact-form-message-input {
  height: 15rem;
  padding: 5px;
  font-family: inherit;
  font-size: 20px;
  font-weight:600;
  resize: none;
}

.send-button {
  height: 3rem;
  width: 10rem;
  align-self: center;
  border-radius: 10px;
  border: 1px solid $dark-blue;
  cursor: pointer;
  background-color: $white;
  color: $dark-blue;
  font-size: 20px;
}

.send-button {
  height: 3rem;
  width: 10rem;
  align-self: center;
  border-radius: 10px;
  border: 1px solid $dark-blue;
  cursor: pointer;
  background-color: $white;
  color: $dark-blue;
  font-size: 20px;
}

.send-button:hover {
  background-color: rgba($white, .7);
}

.send-button[disabled] {
  background-color: rgba(lightgray, .7);
  cursor: default;
}

#message-info-text {
  text-align: center;
}

.footer-wrapper {
  margin: 0;
  color: $dark-blue;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}

.footer-text {
  margin: 0;
  padding: 1rem 0;
}